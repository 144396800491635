<template>
  <div class="content-middle">
    <div class="content_crumbs">
      当前位置：<span style="cursor: pointer;color: rgba(0, 102, 166, 1)" @click="goIndex">集团要闻 > </span>
      <span style="cursor: pointer;color:rgba(0, 102, 166, 1)">正文</span>
    </div>
    <div class="content-xq">
      <div class="middle-left">
        <div class="middle-left_box">
          <div class="choose" :class="i.value == activeIndex ? 'active_left' : ''" v-for="(i, index)  in queryChoose" :key="index" @click="chooseClick(index,i.value)">
            {{ i.name }}
          </div>
        </div>
      </div>
      <div class="middle-right">
        <div class="query-list">
          <div class="notice-title" v-if="data.content!='此公告暂未创建'">
            <div>
              <el-tooltip class="box-item" effect="dark" :content="data.title" placement="top-start">
                <div class="title">{{ data.title }}</div>
              </el-tooltip>
            </div>
            <div style="position: absolute;right: 25px;display: flex;">
              <div class="action-btn_m" style="background: rgba(118, 171, 19, 1);margin-right: 20px;" @click="BiddingUser">
                <img src="./icon/icon1.png" class="left-icon">
                <span>
                  获取标书
                </span>
              </div>
              <div class="action-btn_m" style="background: rgba(64, 158, 255, 1);" @click="BiddingUser">
                <img src="./icon/icon2.png" class="left-icon">
                <span>
                  我要投标
                </span>
              </div>
            </div>
          </div>
          <div class="operation" v-if="data.content!='此公告暂未创建'">
            <div class="btns" @click="Forward"><img src="./icon/icon3.png" class="operate-icon">
              <span class="operation-span">
                转发
              </span>
            </div>
            <div class="btns" @click="print"><img src="./icon/icon4.png" class="operate-icon"> <span class="operation-span">
                打印
              </span></div>
            <div class="btns" @click="collection"><img src="./icon/icon5.png" class="operate-icon"> <span class="operation-span">
                收藏
              </span></div>

          </div>
          <div class="base-info" v-if="data.content!='此公告暂未创建'">
            <el-descriptions :column="2" style="padding: 25px;box-sizing: border-box;background: rgba(245, 247, 250, 1);">
              <el-descriptions-item label="项目编码:" label-class-name="my-label" class-name="my-content">{{ data.projectCode }}
              </el-descriptions-item>
              <el-descriptions-item label="招标项目编码:" label-class-name="my-label" class-name="my-content">{{ data.publicGtpCode }}</el-descriptions-item>
              <el-descriptions-item label="自定义编码(国家):" label-class-name="my-label" class-name="my-content">{{ data.gpCustomCode }}</el-descriptions-item>
              <el-descriptions-item label="招标地区:" label-class-name="my-label" class-name="my-content">{{ getArrName(data.regionId, provinyoptions) }}
              </el-descriptions-item>
              <el-descriptions-item label="招标机构:" label-class-name="my-label" class-name="my-content">{{ data.tenderName }}</el-descriptions-item>
              <el-descriptions-item label="项目类型:" label-class-name="my-label" class-name="my-content">{{ projectTypeF(data.tdrPrjClassifyId, queryChoose1) }}
              </el-descriptions-item>
              <el-descriptions-item label="招标方式:" label-class-name="my-label" class-name="my-content">{{ data.tenderWay }}</el-descriptions-item>
              <el-descriptions-item label="所属行业:" label-class-name="my-label" class-name="my-content">{{ getArrName(data.aicId, allSectors) }}</el-descriptions-item>
              <el-descriptions-item label="截止时间:" label-class-name="my-label" class-name="my-content">{{ data.bidReferEndTime }}
              </el-descriptions-item>
              <el-descriptions-item label="项目状态:" label-class-name="my-label" class-name="my-contentx">
                {{data.flowState<=10?'待发标':data.flowState<=20?'待投标':data.flowState<=40?'待开评':data.flowState<=50?'待定标':data.flowState<=60?'已归档':data.flowState<=70?'已终止':'重新招标'}}
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="html-content">
            <div style="width: 100%;margin-bottom: 20px;" v-html="data.content"></div>
            <div class="text" style="margin-bottom: 10px;">
              本标在 <span style="color: red;">
                齐鲁观水电子招采平台
              </span> 操作，请登录电子招标投标平台并<span style="color: red;">
                使用CA数字证书
              </span> 加密制作投标文件，操作流程见下方说明。
            </div>
            <img style="width: 100%;" src="./icon/steps.png" alt="">
            <div style="font-size: 14px;">
              <span style="color: red;">
                请您知晓：
              </span>
              如果您公司尚未注册请点击“我要注册”。注册信息提交后，我们会在24小时内(不含法定节假日)完成亩核并反馈审核意见，只有亩核通过的供应商才可投标。为确保顺利投标，请您合理安排注册时间并及时登录系统查看审核情况！
            </div>
          </div>
          <div class="notice-btns" v-if="data.content!='此公告暂未创建'">
            <div class="want" style="background: linear-gradient(180deg, rgba(0, 102, 166, 1) 0%, rgba(59, 133, 243, 1) 100%); " @click="BiddingUser">
              我要投标
            </div>
            <a :href="enroll" target="_blank">
              <div class="want" style="background: linear-gradient(180deg, rgba(246, 193, 101, 1) 0%, rgba(224, 168, 50, 1) 100%);">
                我要注册
              </div>
            </a>
            <a :href="transact" target="_blank">
              <div class="want" style="background: linear-gradient(180deg, rgba(47, 225, 210, 1) 0%, rgba(50, 211, 125, 1) 100%);">
                CA办理
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import inspect from '@/api/validate'
import { mainStore } from "@/store";
import { useRouter } from "vue-router";
import { findTenderBulletinType, getByIdAndBulletinType, findGpTenderWay, findByAzcId, findByAtiId, findRootToLevel, getFlagValue } from '@/api/index'
import { ref, onMounted, watch } from 'vue';
import { ElMessage } from 'element-plus'
export default {
  setup() {
    // 1.登录
    const logo = ref()
    let loginCompleted = false;
    const loginUser = async () => {
      const res = await getFlagValue({ flagName: 'loginUrl' });
      logo.value = res.data
      loginCompleted = true;
    };
    // 2.注册
    const enroll = ref()
    const enrollUser = async () => {
      const res = await getFlagValue({ flagName: 'registerurl' });
      enroll.value = res.data
    };

    // 3.数字办理
    const transact = ref()
    const caUrlUser = async () => {
      const res = await getFlagValue({ flagName: 'caUrl' });
      transact.value = res.data
    };

    const router = useRouter();
    const id = ref(null);
    const type = ref(null);
    const gsId = ref(null);
    const goIndex = () => {
      mainStore().setactivePageUrl("/index");
      router.push({
        path: "/index",
      });
    };

    const activeIndex = ref(0)
    const chooseClick = async (index, value) => {
      activeIndex.value = value
      const res = await getByIdAndBulletinType({ bulletinType: value, gsId: gsId.value })
      data.value = res.data
      const tenderWay = await findGpTenderWay()
      tenderWay.forEach(value => {
        if (value.value == data.value.tenderWay) {
          data.value.tenderWay = value.name
        }
      })
    };
    const queryChoose = ref([])
    const pageTable = async () => {
      const res = await findTenderBulletinType();
      res.splice(-2, 2)
      queryChoose.value = res
    };

    const data = ref([])
    const arrTable = async () => {
      const res = await getByIdAndBulletinType({ bulletinType: type.value, id: id.value });
      data.value = res.data
      const tenderWay = await findGpTenderWay()
      tenderWay.forEach(value => {
        if (value.value == data.value.tenderWay) {
          data.value.tenderWay = value.name
        }
      })
    };

    const provinyoptions = ref([])
    const region = async () => {
      const res = await findByAzcId({ bulletinType: 0 })
      provinyoptions.value = res.data
    }

    const queryChoose1 = ref([])
    const projectType = async () => {
      const res = await findByAtiId({ bulletinType: 0 })
      res.data.unshift({
        icon: null,
        id: '',
        name: '全部',
        pid: null,
        state: null,
        url: null
      })
      queryChoose1.value = res.data
    }

    const allSectors = ref([])
    const allIndustries = async () => {
      const res = await findRootToLevel()
      allSectors.value = res.data
    }

    const BiddingUser = () => {
      if (!loginCompleted) {
        loginUser().then(() => {
          window.open(logo.value + '?jumpPageUrl=cms&regTypeName=用户', '_blank');
        });
      } else {
        window.open(logo.value + '?jumpPageUrl=cms&regTypeName=用户', '_blank');
      }
    }

    // 转发
    const Forward = () => {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(window.location.href)
          .then(() => {
            ElMessage({
              message: '链接复制成功，请转发相应媒介。',
              type: 'success',
            })
          })
          .catch(() => {
            ElMessage.error('链接复制失败')
          })
      } else {
        console.warn('当前浏览器不支持访问剪贴板或不是安全上下文')
      }
    }
    // 打印
    const print = () => {
      window.print()
    }
    // 收藏
    const collection = () => {
      if (window.sidebar && window.sidebar.addPanel) { // 兼容Firefox
        window.sidebar.addPanel(document.title, window.location.href, '')
      } else if (navigator.userAgent.indexOf('Chrome') > -1 || navigator.userAgent.indexOf('Safari') > -1) { // 兼容Chrome和Safari
        ElMessage({
          message: '请使用键盘快捷键 Ctrl+D 收藏本页!',
          type: 'warning',
        })
      } else { // 其他浏览器
        ElMessage({
          message: '无法添加收藏页面，请使用浏览器菜单中的收藏功能。',
          type: 'warning',
        })
      }
    }

    onMounted(async () => {
      gsId.value = router.currentRoute.value.query.gsId;
      id.value = router.currentRoute.value.query.id;
      type.value = router.currentRoute.value.query.type;
      await pageTable();
      await arrTable()
      await region()
      await projectType()
      await allIndustries()
      await loginUser()
      await caUrlUser()
      await enrollUser()
    });
    return {
      Forward,
      print,
      collection,
      logo,
      loginUser,
      id,
      type,
      gsId,
      region,
      provinyoptions,
      queryChoose,
      projectType,
      queryChoose1,
      chooseClick,
      activeIndex,
      pageTable,
      arrTable,
      data,
      enroll,
      enrollUser,
      transact,
      caUrlUser,
      allSectors,
      allIndustries,
      goIndex,
      BiddingUser
    };
  },
  methods: {
    getArrName(val, child) {
      const resp = inspect.getFullTreeName(val, child)
      if (resp.valArray) {
        return resp.valArray.join('/')
      }
    },
    projectTypeF(id, array) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].value == id) {
          return array[i].label
        }
        if (array[i].children) {
          const result = this.projectTypeF(id, array[i].children)
          if (result) {
            return result
          }
        }
      }
      return null
    },
  },
};
</script>
<style scoped>
@import url("./content.css");

::v-deep .el-descriptions__body {
  background: none;
}
::v-deep(.my-label) {
  font-family: "思源黑体";
  color: rgba(102, 102, 102, 1) !important;
  font-size: 16px !important;
}
::v-deep(.my-content) {
  font-family: "思源黑体";
  font-size: 16px !important;
  color: rgba(0, 0, 0, 1) !important;
}
::v-deep(.my-contentx) {
  font-family: "思源黑体";
  font-size: 16px !important;
  color: #ff0000;
}
</style>