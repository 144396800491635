<template>
  <div class="head_box">
    <imgHead />
    <Header />
    <contentM />
    <NavFooter />
  </div>
</template>

<script>
import imgHead from "./../secondaryHead/compontents/img/imgHead.vue";
import Header from "./../secondaryHead/compontents/Header/Header.vue";
import NavFooter from "./../secondaryHead/compontents/Footer/Footer.vue";
import contentM from "./compontents/content.vue";
import { getWebTitle } from "@/api/cmsSite/cmsSite";
import { mainStore } from "@/store";
import { ref } from "vue";
export default {
  components: { Header, NavFooter, imgHead, contentM },
  setup() {
    const store = mainStore();
    const webTitle = ref("");
    const aaa = store.getWebData;
    aaa();
    getWebTitle().then((res) => {
      webTitle.value = res.data;
    });
    return {
      webTitle,
    };
  },
  methods: {
    toHelpCenter() {
      this.$parent.toHelpCenter();
    },
  },
};
</script>